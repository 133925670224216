/**
 * Support functions to allow callbacks after the footer scripts are fully loaded.
 * Needed for situations where there is inlined JS dependend on not-yet-loaded code.
 */
// eslint-disable-next-line
var Gutta = Gutta || {};

Gutta.AfterLoad = {};
Gutta.AfterLoad.loaded = false;
Gutta.AfterLoad.callbacks = [];

/**
 * Register callback for execution after loading of scripts is finished.
 * If everything was already loaded (such as when doing this in ajax snippet) the callback is performed immediatelly.
 *
 * @param callback Callback to execute
 */
Gutta.AfterLoad.register = function (callback) {
    if (Gutta.AfterLoad.loaded === true) {
        callback();
    } else {
        Gutta.AfterLoad.callbacks.push(callback);
    }
};

/**
 * Performs all registered callbacks, should be called only once from the last line of scripts.js
 */
Gutta.AfterLoad.trigger = function () {
    Gutta.AfterLoad.loaded = true;
    for (let i = 0; i < Gutta.AfterLoad.callbacks.length; i++) {
        Gutta.AfterLoad.callbacks[i]();
    }
    Gutta.AfterLoad.callbacks = [];
};
